
.duotone {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

.duotone img {
    width: 100%;
}

/* CSS generated layers for duotone shadow/highlight colors */

.duotone::before,
.duotone::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.duotone {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

.duotone img {
    width: 100%;
}

/* CSS generated layers for duotone shadow/highlight colors */

.duotone::before,
.duotone::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

/* highlight color */
@supports (mix-blend-mode: darken){
    .duotone::after{
        background-color: hsla(172, 100%, 50%, 1);
        mix-blend-mode: darken;
    }
}

/* shadow color */
@supports (mix-blend-mode: lighten){
    .duotone::before{
        background-color: hsla(262, 85%, 8%, 1);
        mix-blend-mode: lighten;
    }
}
