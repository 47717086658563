.melon-headline {
    font-family: 'Space Grotesk', sans-serif;
}
.melon-detail {
    font-family: 'Space Mono', monospace;
}
.digital-turq {
    color: #00ffde;
}

.border-digital-turq:hover {
    background-color: #00ffde;
    color: #0f0324;
}

.border-digital-turq {
    border-color: #00ffde;
}
.tech-silver {
    color: #b2bac2
}
